/* You can add global styles to this file, and also import other style files */
$enable-rounded: true;
@import 'variables';
@import 'bootstrap/scss/bootstrap';
@import 'ngx-toastr/toastr';
@import 'bootstrap-icons/font/bootstrap-icons.css';
// @import 'tocbot/src/scss/tocbot';

html {
  scroll-padding-top: 63px;
}

// Override by color.service.ts
:root {
  --bs-primary: #000000;
  --bs-secondary: #a1a1a1;
}

a {
  color: var(--bs-primary);
}

.bg-primary {
  background-color: var(--bs-primary) !important;
}

.btn {
  &-primary {
    --bs-btn-bg: var(--bs-primary);
    --bs-btn-border-color: var(--bs-primary);
    --bs-btn-hover-bg: var(--bs-primary);
    --bs-btn-hover-border-color: var(--bs-primary);
    --bs-btn-active-bg: var(--bs-primary);
    --bs-btn-active-border-color: var(--bs-primary);
    --bs-btn-disabled-bg: var(--bs-primary);
    --bs-btn-disabled-border-color: var(--bs-primary);

    &:hover {
      filter: brightness(1.2);
    }
  }

  &-secondary {
    --bs-btn-hover-bg: var(--bs-secondary);
    --bs-btn-hover-border-color: var(--bs-primary);
    --bs-btn-hover-color: var(--bs-primary);
    --bs-btn-active-bg: var(--bs-secondary);
    --bs-btn-active-border-color: var(--bs-primary);
    --bs-btn-active-color: var(--bs-primary);

    &,
    &:disabled,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: var(--bs-secondary);
    }

    &:hover {
      filter: brightness(0.9);
    }
  }
}

.dropdown-menu {
  --bs-dropdown-link-bg: var(--bs-secondary);
  --bs-dropdown-link-border-color: var(--bs-secondary);
  --bs-dropdown-link-hover-bg: var(--bs-secondary);
  --bs-dropdown-link-hover-border-color: var(--bs-secondary);
  --bs-dropdown-link-active-bg: var(--bs-secondary);
  --bs-dropdown-link-active-border-color: var(--bs-secondary);
  --bs-dropdown-link-disabled-bg: var(--bs-secondary);
  --bs-dropdown-link-disabled-border-color: var(--bs-secondary);
}

.border-primary {
  border-color: var(--bs-primary) !important;
}

.form-control:focus {
  border-color: var(--bs-primary);
}

.youtubeContainer {
  width: 60vh;
  height: 33.75vh;
  max-width: 100%;
  margin: 10px;
  background: var(--bs-secondary);

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  color: $white;
  text-align: center;
}

/**
 * Toast
 */
.toast {
  &-bottom-center {
    right: 1rem;
    width: auto;

    @include media-breakpoint-up(md) {
      bottom: 3rem;
    }
  }

  &-info {
    background-image: unset;

    &:before {
      content: '';
      position: absolute;
      left: 0px;
      top: 0px;
      height: 100%;
      overflow: hidden;
      padding: 15px 15px 15px 30px;
      background-position: 50% center;
      background-repeat: no-repeat;
      background-size: 24px;
      color: #ffffff;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M96,256c0-26.5-21.5-48-48-48S0,229.5,0,256c0,26.5,21.5,48,48,48S96,282.5,96,256z M108.9,355.1c-26.5,0-48,21.5-48,48 c0,26.5,21.5,48,48,48c26.5,0,48-21.5,48-48C156.9,376.6,135.4,355.1,108.9,355.1z M108.9,60.9c-26.5,0-48,21.5-48,48 s21.5,48,48,48c26.5,0,48-21.5,48-48S135.4,60.9,108.9,60.9z M403.1,156.9c26.5,0,48-21.5,48-48s-21.5-48-48-48 c-26.5,0-48,21.5-48,48S376.6,156.9,403.1,156.9z M403.1,355.1c-26.5,0-48,21.5-48,48c0,26.5,21.5,48,48,48s48-21.5,48-48 C451.1,376.6,429.6,355.1,403.1,355.1z M256,416c-26.5,0-48,21.5-48,48s21.5,48,48,48s48-21.5,48-48S282.5,416,256,416z M464,208 c-26.5,0-48,21.5-48,48c0,26.5,21.5,48,48,48s48-21.5,48-48C512,229.5,490.5,208,464,208z M256,0c-26.5,0-48,21.5-48,48 c0,26.5,21.5,48,48,48s48-21.5,48-48C304,21.5,282.5,0,256,0z'/%3E%3C/svg%3E");

      animation: spin 4s linear infinite;

      @keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
    }
  }
}

/**
 * Right click context menu
 */
.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ngx-contextmenu.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
}

// not required but can help with mobile right click
.target {
  user-select: none;
}

#toc ol {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

#toc ol ol {
  padding-left: 5px;
}

#toc a {
  display: block;
  padding: 0.125rem 0 0.125rem 8px;
  color: gray;
  text-decoration: none;
  border-left: 3px solid transparent;
}

#toc a:hover,
#toc a.active {
  color: var(--bs-primary);
  border-left-color: var(--bs-primary);
}

#toc a.active {
  font-weight: bold;
  background: color-mix(in srgb, currentColor 20%, transparent);
}

.modal {
  --bs-modal-border-color: var(--bs-primary);

  .modal-dialog {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
    transition: none !important;
    width: var(--bs-modal-width);
    max-width: 80vw;
  }
}

typeahead-container {
  max-width: calc(60vw - 95px);

  @media only screen and (max-width: 990px) {
    max-width: calc(100vw - 78px);
  }
}
